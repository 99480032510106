
import 'firebase/storage'

export default {
  data () {
    return {
      text: {},
      TextBible: {},
      arrayBooks: []
    }
  },
  async mounted () {
    const response = await fetch('https://blv-vue3-tp.firebaseio.com/books.json')
    const data = await response.json()

    this.arrayBooks = Object.keys(data).map(key => {
      return { ...data[key], id: key }
    })
  },
  methods: {
    async deleteText (id) {
      this.$swal({
        title: 'Зачекайте...',
        text: '',
        imageUrl: '352.gif' || '~~/assets/352.gif',
        showConfirmButton: false
      })

      await fetch(`https://blv-vue3-tp.firebaseio.com/books/${id}.json`, {
        method: 'DELETE'
      })

      const response = await fetch('https://blv-vue3-tp.firebaseio.com/books.json')
      const data = await response.json()

      this.arrayVerse = Object.keys(data).map(key => {
        return { ...data[key], id: key }
      })

      try {
        this.$swal('Вірш видаленно!!!')
        this.text.title = ''
        this.text.verse = ''
      } catch (error) {
        console.log(error)
      }
    }
  }
}
